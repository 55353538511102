import './App.css';
import AccessCard from './Components/AccessCard';

function App() {
  return (
    <div className="App">
      <AccessCard portal="CAQVIX" />
    </div>
  );
}

export default App;
