/*
Copyright (C) 2022 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of autentica.

autentica is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

autentica is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with autentica.  If not, see <https://www.gnu.org/licenses/>.
*/

import React from 'react';
import Cookies from 'universal-cookie';

function AccessCard(props) {
    const { portal } = props;

    function  checkPassword(){
        let typed = document.getElementById('input-'+portal).value
        let expected = process.env['REACT_APP_' + portal]
        if (typed === expected){
            const cookies = new Cookies();
            cookies.set(portal, process.env['REACT_APP_' + portal], { path: '/' });
            window.location.replace('https://caqvix.c3sl.ufpr.br')
        }
    }

    // On load disallow client
    const cookies = new Cookies();
    cookies.set(portal, 'not_allowed', { path: '/' });

    return (
        <div id={'card'} style={cardStyle}>
            <div style={contentStyle}>
                <p style={pStyle}>Digite a senha de acesso para o sistema {portal}</p>
                <form onSubmit={checkPassword}>
                    <input
                        id={'input-'+portal}
                        type={'password'}
                        style={inputStyle}
                    />
                    <button
                        id={'button-'+portal}
                        type="submit"
                        style={buttonStyle}
                    >
                        <strong>Autenticar</strong>
                    </button>
                </form>
            </div>
        </div>
    )
}

const cardStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40vh',
}

const contentStyle = {
    background: '#D3D3D3',
    borderRadius: '10px',
    padding: '20px',
}

const pStyle = {
    marginTop: 0,
}

const inputStyle = {
    marginRight: '10px',
    padding: '8px',
    borderRadius: '6px',
    border: 'none',
}

const buttonStyle = {
    background: '#1680c4',
    border: 'none',
    borderRadius: '8px',
    padding: '10px',
    color: '#fff',
    cursor: 'pointer',
}

export default AccessCard;
